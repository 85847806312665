import { format } from 'date-fns'
import type { AddressDTO, BookingDrivers, BookingTableQueryFilters } from '~/types/booking'
import type { ColumnDto, DateRange, TableColumn, TableColumnSort } from '~/types/common/table'
import { TABLE_FILTER_DATE_FORMAT } from '~/constants/table'
import {
  type BookingValidationStatusEnum,
  type BookingStatusEnum, BookingTypesEnum
} from '~/constants/bookings'
import { BOOKING_STATUS_COLORS, BOOKING_VALIDATION_STATUS_COLORS } from '~/constants/bookings'

export function buildBookingFiltersQuery(
  terms?: string,
  dateRange?: DateRange,
  selectedDrivers?: BookingDrivers[],
  validationStatus?: BookingValidationStatusEnum[],
  sort?: TableColumnSort,
  bookingStatus?: BookingStatusEnum[],
  pageNumber?: number, pageSize: number, columns: TableColumn[]
): BookingTableQueryFilters {
  const filtersQuery: BookingTableQueryFilters = {
    pageNumber, pageSize
  }

  if (terms?.length) {
    filtersQuery.searchQuery = terms
  }

  if (dateRange?.start) {
    filtersQuery.dateStart = format(dateRange.start, TABLE_FILTER_DATE_FORMAT)
  }

  if (dateRange?.end) {
    filtersQuery.dateEnd = format(dateRange.end, TABLE_FILTER_DATE_FORMAT)
  }

  if (selectedDrivers?.length) {
    filtersQuery.selectedVehicleIds = selectedDrivers.map(e => e.vehicleId).join(',')
  }

  if (validationStatus?.length) {
    filtersQuery.validationStatuses = validationStatus.join(',')
  }

  if (bookingStatus?.length) {
    filtersQuery.bookingStatuses = bookingStatus.join(',')
  }

  if (sort?.column && sort?.direction) {
    console.log('SORT', columns)
    const columnSelected = columns.find(elem => elem.key === sort.column)
    if (columnSelected) {
      console.log('columnSelected', columnSelected)
      filtersQuery.sort = `${columnSelected.sortKey},${sort.direction.toUpperCase()}`
    }
  }

  return filtersQuery
}

export function buildColumnsWithSavedSettings(initialColumns: ColumnDto[]): TableColumn[] {
  const initialColumnsMap: Map<number, TableColumn> = new Map(
    initialColumns.map((elem, index) => [
      index,
      { ...elem, id: index, visible: true }
    ])
  )

  // Only attempt to use localStorage on the client side
  if (import.meta.client) {
    const savedColumns = localStorage.getItem('columnSettings')
    if (!savedColumns || savedColumns.length === 0) {
      return Array.from(initialColumnsMap.values())
    }

    try {
      const savedColumnsList: TableColumn[] = JSON.parse(savedColumns)
      const savedColumnsMap: Map<number, TableColumn> = new Map(savedColumnsList.map(col => [col.id, col]))
      const orderedColumns: TableColumn[] = []

      initialColumns.forEach((initialCol, index) => {
        const savedCol = savedColumnsMap.get(index)

        if (savedCol) {
          orderedColumns.push({ ...initialCol, visible: savedCol.visible, id: index })
        } else {
          orderedColumns.push({ ...initialCol, visible: true, id: index })
        }
      })

      return orderedColumns
    } catch (error) {
      console.error('Error parsing saved column settings:', error)
      return Array.from(initialColumnsMap.values())
    }
  }

  // If we're on the server side, just return the initial columns
  return Array.from(initialColumnsMap.values())
}

export const getBookingStatusColor = (statusLabel?: string): string => {
  if (!statusLabel) return ''
  return BOOKING_STATUS_COLORS[statusLabel as keyof typeof BookingStatusEnum] || ''
}

export const getBookingValidationStatusColor = (statusLabel: string): string => {
  return BOOKING_VALIDATION_STATUS_COLORS[statusLabel as keyof typeof BookingValidationStatusEnum] || 'orange'
}

export function formatBookingAddress(appointmentAddress: AddressDTO): string {
  if (!appointmentAddress) return ''

  const street = appointmentAddress.street || ''
  const postalCode = appointmentAddress.postalCode || ''
  const city = appointmentAddress.city || ''
  const complementaryInformations = appointmentAddress.complementaryInformations || ''

  return `${street} \n${postalCode} ${city} \n${complementaryInformations}`
}

export const getBookingTypeIcon = (bookingType?: BookingTypesEnum): string => {
  if (!bookingType) return ''
  return bookingType === BookingTypesEnum.ONE_WAY ? 'i-heroicons-arrow-right-16-solid' : 'i-heroicons-arrow-left-16-solid'
}
