import { createSharedComposable } from '@vueuse/core'

const _useDashboard = (): {
  isFiltersOpen: globalThis.Ref<boolean, boolean>
} => {
  const isFiltersOpen = ref(false)

  return {
    isFiltersOpen
  }
}

export const useDashboard = createSharedComposable(_useDashboard)
