import type { BookingTableQueryFilters } from '~/types/booking'

export const BOOKING_FETCH_INTERVAL = 10000

export const BOOKING_FILTER_DATE_RANGES = [
  { label: 'Hier', duration: { days: 1 } },
  { label: 'Aujourd\'hui', duration: { days: 0 } },
  { label: 'Cette semaine', duration: { days: 7 } },
  { label: 'Ce mois', duration: { months: 1 } },
  { label: 'Cette année', duration: { years: 1 } }
]

export enum BookingValidationStatusEnum {
  PENDING = 'PENDING',
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  PRE_ASSIGNED = 'PRE_ASSIGNED'
}

export enum BookingStatusEnum {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  UPCOMING = 'UPCOMING'
}

export enum BookingTabsEnum {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  VALIDATED = 'VALIDATED',
  ALL = 'all'
}

export enum BookingTypesEnum {
  ONE_WAY = 'ONE_WAY',
  RETURN_TRIP = 'RETURN_TRIP'
}

export const BOOKING_STATUS_COLORS = {
  [BookingStatusEnum.IN_PROGRESS]: 'green',
  [BookingStatusEnum.COMPLETED]: 'violet',
  [BookingStatusEnum.UPCOMING]: 'blue',
  [BookingStatusEnum.CANCELLED]: 'red'
}

export const BOOKING_VALIDATION_STATUS_COLORS = {
  [BookingValidationStatusEnum.PENDING]: 'orange',
  [BookingValidationStatusEnum.VALIDATED]: 'green',
  [BookingValidationStatusEnum.REJECTED]: 'red',
  [BookingValidationStatusEnum.PRE_ASSIGNED]: 'blue'
}

export const BOOKING_TABS_FILTERS_ICONS = {
  [BookingTabsEnum.PENDING]: 'i-heroicons-clock',
  [BookingTabsEnum.IN_PROGRESS]: 'i-heroicons-arrow-path',
  [BookingTabsEnum.VALIDATED]: 'i-heroicons-check-circle',
  [BookingTabsEnum.ALL]: 'i-heroicons-archive-box'
}

export const BOOKING_DEFAULT_FILTER: BookingTableQueryFilters = {
  validationStatuses: [BookingValidationStatusEnum.PENDING, BookingValidationStatusEnum.PRE_ASSIGNED],
  pageNumber: 1,
  pageSize: 20
}
