import { type Duration, set } from 'date-fns'
import { endOfMonth, endOfWeek, endOfYear, format, startOfMonth, startOfWeek, startOfYear, sub, parse } from 'date-fns'
import { fr } from 'date-fns/locale'
import type { DateRange } from '~/types/common/table'

export function calcDateRange(duration: Duration): DateRange {
  let selectedDateRange: DateRange = { start: sub(new Date(), duration), end: new Date() }
  const now = new Date()

  if (duration.days !== undefined) {
    if (duration.days === 0) {
      selectedDateRange = { start: now, end: now }
    } else if (duration.days === 7) {
      selectedDateRange = { start: startOfWeek(now, { weekStartsOn: 1 }), end: endOfWeek(now, { weekStartsOn: 1 }) }
    } else {
      selectedDateRange = { start: sub(now, duration), end: sub(now, duration) }
    }
  } else if (duration.months !== undefined) {
    selectedDateRange = { start: startOfMonth(now), end: endOfMonth(now) }
  } else if (duration.years !== undefined) {
    selectedDateRange = { start: startOfYear(now), end: endOfYear(now) }
  }
  return selectedDateRange
}

export function constructFormattedDateRange(selectedDateRange: DateRange): string {
  if (selectedDateRange.start) {
    const formattedStart = format(selectedDateRange.start, 'd MMM yyyy', { locale: fr })
    if (!selectedDateRange.end || selectedDateRange.start.getTime() === selectedDateRange.end.getTime()) {
      return formattedStart
    } else {
      const formattedEnd = format(selectedDateRange.end, 'd MMM yyyy', { locale: fr })
      return `${formattedStart} - ${formattedEnd}`
    }
  }
  return 'Sélectionnez une date'
}

export function constructRegulateDate(departureDate, selectedHour, selectedMinute): string {
  let departureDateTime = ''
  if (departureDate && selectedHour !== null && selectedMinute !== null) {
    const date = parse(departureDate, 'dd/MM/yyyy', new Date(), { locale: fr })
    const dateWithTime = set(date, {
      hours: selectedHour,
      minutes: selectedMinute,
      seconds: 0,
      milliseconds: 0,
      locale: fr
    })

    console.log('dateWithTime', dateWithTime)

    departureDateTime = dateWithTime.toISOString()
  } else throw new Error('Bad Date Format: ', departureDate, selectedHour, selectedMinute)
  return departureDateTime
}
